import React, { useState, useEffect } from "react"

import { Map, TileLayer, Marker } from "react-leaflet"

import Layout from "../components/layout"
import SEO from "../components/seo"

import fetchJsonp from "fetch-jsonp"

var isBase64 = require("is-base64")

require("es6-promise").polyfill()

function calculateSalary(
  Currency,
  SalaryFrom,
  SalaryTo,
  SalaryType,
  SalaryPeriod
) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  let NewSalary
  let NewSalaryFrom
  let NewSalaryTo

  let currencyFormatted = Currency

  if (Currency === "&pound;") {
    currencyFormatted = "£"
  }

  if (SalaryTo === 0) {
    NewSalary = SalaryType
  } else if (SalaryFrom === SalaryTo) {
    if (SalaryFrom >= 1000) {
      // Remove decimals
      NewSalaryFrom = currencyFormatted + numberWithCommas(SalaryFrom.toFixed())
    } else {
      // Add two decimals
      NewSalaryFrom =
        currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2))
    }
    NewSalary = NewSalaryFrom + " " + SalaryPeriod
  } else {
    if (SalaryFrom >= 1000) {
      NewSalaryFrom = currencyFormatted + numberWithCommas(SalaryFrom.toFixed())
    } else {
      NewSalaryFrom =
        currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2))
    }
    if (SalaryTo >= 1000) {
      NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed())
    } else {
      NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed(2))
    }

    NewSalary = NewSalaryFrom + " - " + NewSalaryTo + " " + SalaryPeriod
  }
  return NewSalary
}

function CheckJobHasNotExpired(JSONDate) {
  if (JSONDate) {
    let expDate = new Date(parseInt(JSONDate.substr(6), 10))
    let now = new Date()

    if (expDate > now) {
      return true
    }

    return false
  } else {
    return false
  }
}

function FormatDateTime(JSONDate) {
  if (JSONDate) {
    // Set default values to avoid function breaking
    var expDate = new Date(parseInt(JSONDate.substr(6)))
    var dateFormatOptions = new Object({})
    dateFormatOptions.weekday = "long"
    dateFormatOptions.year = "numeric"
    dateFormatOptions.month = "short"
    dateFormatOptions.day = "numeric"
    return expDate.toLocaleDateString(undefined, dateFormatOptions)
  } else {
    return JSONDate
  }
}

function generateGoogleJobsSchema(vacancy) {
  return {
    "@context": "http://schema.org",
    "@type": "JobPosting",
    baseSalary: calculateSalary(
      vacancy.Currency,
      vacancy.SalaryFrom,
      vacancy.SalaryTo,
      vacancy.SalaryType,
      vacancy.SalaryPeriod
    ),
    jobBenefits: vacancy.Benefits,
    datePosted: FormatDateTime(vacancy.PostingDate),
    validThrough: FormatDateTime(vacancy.ExpiryDate),
    description: vacancy.Description,
    employmentType: vacancy.JobTypeTime,
    industry: vacancy.Industry,
    jobLocation: {
      "@type": "Place",
      geo: {
        longitude: vacancy.Longitude,
        latitude: vacancy.Latitude,
        "@type": "GeoCoordinates",
      },
      address: {
        "@type": "PostalAddress",
        addressCountry: vacancy.Country,
        addressLocality: vacancy.Location,
        addressRegion: vacancy.Region,
        postalCode: vacancy.PostCode,
      },
    },
    occupationalCategory: vacancy.JobCategory,
    salaryCurrency: vacancy.Currency === "&pound;" ? "GBP" : "EUR",
    hiringOrganization: {
      "@type": "Organization",
      name: vacancy.Company,
      logo: vacancy.CompanyLogoPath,
    },
    title: vacancy.JobTitle,
    workHours: vacancy.JobTypeTime,
  }
}

const listenForIFrameHeight = function listenForIFrameHeight() {
  if (typeof window !== "undefined") {
    window.addEventListener(
      "message",
      function (event) {
        let careersPageiFrame = null

        if (document.getElementById("vf_iFrame_application_form")) {
          careersPageiFrame = document.getElementById(
            "vf_iFrame_application_form"
          )
        }

        if (
          careersPageiFrame !== null &&
          careersPageiFrame !== undefined &&
          careersPageiFrame !== "undefined"
        ) {
          let careersPageEvent = event.data[0]
          let careersPageHeight = event.data[1]

          switch (careersPageEvent) {
            case "setCareersPageHeight":
              let careersPageHeightExtra = careersPageHeight + 50

              console.log("cp height")
              console.log(careersPageHeightExtra)

              careersPageiFrame.height = careersPageHeightExtra
              break
          }
        }
      },
      false
    )
  }
}

const SmartfeedIndividual = ({ id = 1, vacancyTitlePath = false }) => {
  const [vacancy, setVacancy] = useState([])
  const [showApplication, setshowApplication] = useState(false)
  const [vacancyTitle, setVacancyTitle] = useState("Loading vacancy....")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    listenForIFrameHeight()
    setshowApplication(false)
    const timeOut = setTimeout(() => {
      if (isBase64(id) && vacancyTitlePath) {
        fetchVacancy(new Buffer(id, "base64").toString())
      } else {
        fetchVacancy(id)
      }
    }, 50)
    return () => clearTimeout(timeOut)
  }, [id])

  function vfScrollToElement(element, to, duration) {
    if (duration <= 0) return
    var difference = to - element.scrollTop
    var perTick = (difference / duration) * 10

    setTimeout(function () {
      element.scrollTop = element.scrollTop + perTick
      if (element.scrollTop === to) return
      window.scrollTo(element, to, duration - 10)
    }, 10)
  }

  function animate(elem, style, unit, from, to, time, prop) {
    if (!elem) {
      return
    }
    var start = new Date().getTime(),
      timer = setInterval(function () {
        var step = Math.min(1, (new Date().getTime() - start) / time)
        if (prop) {
          elem[style] = from + step * (to - from) + unit
        } else {
          elem.style[style] = from + step * (to - from) + unit
        }
        if (step === 1) {
          clearInterval(timer)
        }
      }, 25)
    if (prop) {
      elem[style] = from + unit
    } else {
      elem.style[style] = from + unit
    }
  }

  function activateApplicationForm(setting) {
    setshowApplication(setting)

    var target = document.getElementById("ApplicationScrollTarget")
    animate(
      document.scrollingElement || document.documentElement,
      "scrollTop",
      "",
      0,
      target.offsetTop,
      5,
      true
    )
  }

  const fetchVacancy = async (id) => {
    setLoading(true)
    try {
      fetchJsonp("https://sf.vacancy-filler.co.uk/CareerPage/GetItem?id=" + id)
        .then(function (response) {
          return response.json()
        })
        .then(function (json) {
          const responseVacancy = json
          const resultVacancy = responseVacancy
          setLoading(false)
          setVacancy(resultVacancy)
          setVacancyTitle(resultVacancy.JobTitle)
          return resultVacancy
        })
        .catch(function (ex) {
          return fetchJsonp(
            `https://sf.vacancy-filler.co.uk/CareerPage/GetItem?id=${new Buffer(
              id,
              "base64"
            ).toString()}`
          )
            .then(function (response) {
              return response.json()
            })
            .then(function (json) {
              const responseVacancy = json
              const resultVacancy = responseVacancy
              setLoading(false)
              setVacancy(resultVacancy)
              setVacancyTitle(resultVacancy.JobTitle)
              return resultVacancy
            })
            .catch(function (ex) {
              setLoading(false)
              console.log(ex, "error")
            })
        })

      /*  
     JSON method, sadly doesn't work with SF
     const fetchingVacancy = await fetch(
        `https://sf.vacancy-filler.co.uk/CareerPage/GetItem?callback=gatsby&id=${id}`
      )
      const responseVacancy = await fetchingVacancy.json()
      const resultVacancy = await responseVacancy
      setLoading(false)
      setVacancy(resultVacancy)
      return resultVacancy 
      */
    } catch (error) {
      setLoading(false)
      console.log(error.message, "error")
    }
  }

  return (
    <Layout>
      <SEO title={loading ? "Loading vacancy..." : vacancyTitle} />
      <div className="main-container">
        {!vacancy ||
          vacancy == [] ||
          (!vacancy.Description && (
            <div class="hero hero-black">
              <div class="container">
                <div class="holder">
                  <div class="wrap-in">
                    <div class="row">
                      <div class="col-lg-5 col-xl-4">
                        <div class="text-block">
                          <h1 className="vf-jobtitle">{vacancyTitle}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span class="triangle"></span>
                </div>
              </div>
              <figure class="img">
                <div class="img-hold ">
                  {/* <img
                    width="1240"
                    height="460"
                    src="https://cdn1.accropress.com/Activate%20Learning/single_page_header.png"
                    class="attachment-hero-bg size-hero-bg ls-is-cached lazyloaded"
                    alt=""
                    loading="lazy"
                  /> */}
                </div>
              </figure>
            </div>
          ))}

        {vacancy && vacancy != [] && vacancy.Description && (
          <div>
            {vacancy.PostingDate &&
              vacancy.ExpiryDate &&
              CheckJobHasNotExpired(vacancy.ExpiryDate) && (
                <script
                  type="application/ld+json"
                  dangerouslySetInnerHTML={{
                    __html: JSON.stringify(generateGoogleJobsSchema(vacancy)),
                  }}
                />
              )}

            <div class="hero hero-black">
              <div class="container">
                <div class="holder">
                  <div class="wrap-in">
                    <div class="row">
                      <div class="col-lg-5 col-xl-4">
                        <div class="text-block">
                          {!vacancy ||
                            vacancy == [] ||
                            (!vacancy.Description && (
                              <h1 className="vf-jobtitle">
                                Loading vacancy...
                              </h1>
                            ))}
                          {vacancy && vacancy != [] && vacancy.Description && (
                            <h1 className="vf-jobtitle">{vacancyTitle}</h1>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <span class="triangle"></span>
                </div>
              </div>
              <figure class="img">
                <div class="img-hold ">
                  {/* <img
                    width="1240"
                    height="460"
                    src="https://cdn1.accropress.com/Activate%20Learning/single_page_header.png"
                    class="attachment-hero-bg size-hero-bg ls-is-cached lazyloaded"
                    alt=""
                    loading="lazy"
                  /> */}
                </div>
              </figure>
            </div>

            <div className="container">
              <div className="row vacancyDetailsWrap">
                <div id="ApplicationScrollTarget"></div>

                <div className="col-12 col-md-8 job-description">
                  {showApplication && (
                    <div className="vf_iFrame_wrapper">
                      <iframe
                        id="vf_iFrame_application_form"
                        src={
                          vacancy.Link + "&action=ApplyNow&HideCareersLink=true"
                        }
                      />
                    </div>
                  )}

                  {!showApplication && (
                    <div className="vf-JobDescription">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: vacancy.Description,
                        }}
                      />
                    </div>
                  )}

                  <div className="vf-JobActions">
                    <a
                      href="https://activatelearningcareers.co.uk/"
                      className="mb-2 btn btn-secondary d-block"
                      id="vacancy-page-back-search-button"
                    >
                      Return to listings
                    </a>

                    <div className="mb-4 vf-ApplyButton-bottom">
                      {!showApplication && (
                        <button
                          className="vf-apply-button ui button btn btn-primary"
                          onClick={() => {
                            activateApplicationForm(true)
                          }}
                        >
                          Apply now
                        </button>
                      )}
                      {showApplication && (
                        <button
                          className="vf-apply-button ui button btn btn-primary"
                          onClick={() => {
                            activateApplicationForm(false)
                          }}
                        >
                          Go back to job description
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div id="sidebar-blocks" className="col-12 col-md-4">
                  <div className="sidebar-block-adv">
                    <div className="heading">
                      <h3>Job Summary</h3>
                    </div>

                    <div className="content">
                      <div className="vf-details mt-2">
                        <div className="vf-job-info-container">
                          <div className="vf-job-info-title">Reference</div>
                          <div className="vf-job-info-value">
                            <span className="vf-AdvertReference">
                              {vacancy.AdvertReference}
                            </span>
                          </div>
                        </div>
                        <div className="vf-job-info-container">
                          <div className="vf-job-info-title">Category</div>
                          <div className="vf-job-info-value">
                            <span className="vf-category">
                              {vacancy.JobCategory}
                            </span>
                          </div>
                        </div>
                        <div className="vf-job-info-container">
                          <div className="vf-job-info-title">Location</div>
                          <div className="vf-job-info-value vf-Location">
                            {vacancy.Location}
                          </div>
                        </div>
                        <div className="vf-job-info-container">
                          <div className="vf-job-info-title">Job Type</div>
                          <div className="vf-job-info-value">
                            <span className="vf-JobType">
                              {vacancy.JobType}
                            </span>{" "}
                            /
                            <span className="vf-JobTypeTime">
                              {vacancy.JobTypeTime}
                            </span>
                          </div>
                        </div>
                        <div className="vf-job-info-container">
                          <div className="vf-job-info-title">Salary</div>
                          <div className="vf-job-info-value vf-Salary">
                            {calculateSalary(
                              vacancy.Currency,
                              vacancy.SalaryFrom,
                              vacancy.SalaryTo,
                              vacancy.SalaryType,
                              vacancy.SalaryPeriod
                            )}
                          </div>
                        </div>
                        <div className="vf-job-info-container">
                          <div className="vf-job-info-title">Expiry</div>
                          <div className="vf-job-info-value vf-ExpiryDate">
                            {FormatDateTime(vacancy.ExpiryDate)}
                          </div>
                        </div>

                        {vacancy.Attachments &&
                          vacancy.Attachments != [] &&
                          vacancy.Attachments.length >= 0 && (
                            <ul className="vf-Attachments">
                              {vacancy.Attachments &&
                                vacancy.Attachments.map((attachment, i) => (
                                  <li id={attachment.S3Name} className="row">
                                    <a
                                      className="btn-filedownload vf-row"
                                      href={attachment.Location}
                                      target="_blank"
                                    >
                                      <span className="filetype filetype_icon vf-col-xs-2">
                                        <span className="file outline icon"></span>
                                      </span>
                                      <span className="details vf-col-xs-8">
                                        <span className="filename">
                                          {attachment.FileName}
                                        </span>
                                      </span>
                                    </a>
                                  </li>
                                ))}
                            </ul>
                          )}

                        {vacancy.Latitude != "" &&
                          vacancy.Latitude != null &&
                          vacancy.Latitude != 0 && (
                            <Map
                              center={[vacancy.Latitude, vacancy.Longitude]}
                              zoom="12"
                              pitch="0"
                              bearing="0"
                              scrollZoom="false"
                              pitchWithRotate="false"
                              dragRotate="false"
                            >
                              <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />
                              <Marker
                                position={[vacancy.Latitude, vacancy.Longitude]}
                              />
                            </Map>
                          )}
                      </div>

                      <hr />

                      <div className="vf-ApplyButton-sidebar">
                        {!showApplication && (
                          <button
                            className="vf-apply-button ui button btn btn-primary"
                            onClick={() => {
                              activateApplicationForm(true)
                            }}
                          >
                            Apply now
                          </button>
                        )}
                        {showApplication && (
                          <button
                            className="vf-apply-button ui button btn btn-primary"
                            onClick={() => {
                              activateApplicationForm(false)
                            }}
                          >
                            Go back to job description
                          </button>
                        )}
                      </div>

                      <div id="share-icons">
                        <div className="vf-SocialButtons">
                          <a
                            data-sharelinkend=""
                            href={
                              "https://www.facebook.com/sharer/sharer.php?u=" +
                              "https://activatelearningcareers.co.uk/vacancies/vacancy/" +
                              encodeURIComponent(vacancy.Id) +
                              "%26ExtraData=%26utm_source=social%26utm_campaign=sharing_buttons"
                            }
                            target="_blank"
                            rel="nofollow"
                            className="vf-facebook-sharing-button vf-shareButton vf-activate-sharing-button"
                          >
                            <span className="icon facebook"></span>
                          </a>
                          <a
                            href={
                              "https://twitter.com/intent/tweet?text=" +
                              vacancyTitle +
                              " - " +
                              "https://activatelearningcareers.co.uk/vacancies/vacancy/" +
                              encodeURIComponent(vacancy.Id) +
                              "%26ExtraData=%26utm_source=social%26utm_campaign=sharing_buttons"
                            }
                            target="_blank"
                            rel="nofollow"
                            className=" vf-twitter-sharing-button vf-shareButton vf-activate-sharing-button"
                          >
                            <span className="icon twitter"></span>
                          </a>
                          <a
                            href={
                              "https://www.linkedin.com/shareArticle?mini=true&url=" +
                              "https://activatelearningcareers.co.uk/vacancies/vacancy/" +
                              encodeURIComponent(vacancy.Id) +
                              "%26ExtraData=%26utm_source=social%26utm_campaign=sharing_buttons" +
                              "&title=" +
                              vacancyTitle +
                              "&summary=&source="
                            }
                            target="_blank"
                            rel="nofollow"
                            className=" vf-linkedin-sharing-button vf-shareButton vf-activate-sharing-button"
                          >
                            <span className="icon linkedin"></span>
                          </a>
                          <a
                            href={
                              "mailto:?&subject=Check out this vacany&body=Check out this " +
                              vacancy.JobTitle +
                              " vacancy " +
                              " - https://activatelearningcareers.co.uk/vacancies/vacancy/" +
                              encodeURIComponent(vacancy.Id) +
                              "%26ExtraData=%26utm_source=social%26utm_campaign=sharing_buttons"
                            }
                            target="_blank"
                            rel="nofollow"
                            className=" vf-email-sharing-button vf-shareButton vf-activate-sharing-button"
                          >
                            <span className="icon envelope"></span>
                          </a>
                          <a
                            href="javascript:window.print()"
                            rel="nofollow"
                            className=" vf-print-sharing-button vf-shareButton"
                            aria-label="Print"
                          >
                            <span className="icon print"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default SmartfeedIndividual
